import React from "react"
import BlockWrapper from "../technical/BlockWrapper"
import Image from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Image"
import Headline from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Headline"
import Richtext from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Richtext"
import Button from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Button"

const PromoBlock = ({ block }) => {
  if (block.block_bg === undefined || block.block_bg?.length === 0) {
    block.block_bg = "bg-primary text-white"
  }

  const hasContent =
    block.headline?.length > 0 ||
    block.text?.length > 0 ||
    block.button?.length > 0

  return (
    <>
      {block.image?.filename?.length > 0 && (
        <BlockWrapper
          block={block}
          blockPadding=""
          blockWidth="screen"
          showHeadline="false"
        >
          <div
            className={`relative mx-auto overflow-hidden bg-black ${
              block.reduced_height ? "md:h-[50vh]" : "md:h-[60vh]"
            }`}
          >
            <Image
              image={block.image}
              title={block.title}
              alt={block.alt}
              className="object-cover w-full h-auto md:h-full"
              adaptToPixelRatio={true}
            />
            {hasContent && (
              <div
                className={`inset-0 bg-ruko-black md:absolute md:bg-transparent ${
                  block?.use_gradient
                    ? "md:bg-gradient-to-r from-black-2/3-transparent to-transparent"
                    : ""
                }`}
              >
                <div className="h-full max-w-screen-xl px-4 py-8 mx-auto">
                  <div className="flex flex-col justify-between h-full md:w-1/2">
                    <Headline headlineLevel="h2" headline={block.headline} />
                    <div>
                      <Richtext text={block.text} />
                      {block.buttons?.length > 0 && (
                        <div className="mt-8 -mb-4">
                          {block.buttons?.map((button, index) => (
                            <Button
                              key={index}
                              className={`mb-4 ${
                                index + 1 < block.buttons.length ? "mr-4" : ""
                              }`}
                              btnType={index === 0 ? "primary" : "secondary"}
                              link={button.link}
                              target={button?.target === true ? "_blank" : ""}
                              externalIcon={button.external_icon}
                              icon={button.icon}
                            >
                              {button.link_text}
                            </Button>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </BlockWrapper>
      )}
    </>
  )
}

export default PromoBlock
